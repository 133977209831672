var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comments-settings"},[_c('page-title',[_c('save-channel-config',{attrs:{"slot":"custom-button","disabled":!_vm.$store.getters.hasChannelGroup},slot:"custom-button"})],1),_c('a-card',[_c('a-alert',{attrs:{"show-icon":"","type":"warning"}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('channel_group_not_connected_alert_message', {
          bot: _vm.$store.state.boardsState.activeBoard.title,
          channel: _vm.activeChannel.title
        }))},slot:"message"})]),_c('switch-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'delete_chat_service_messages',
          'hasAnchor': true,
          'disabled': !_vm.$store.getters.hasChannelGroup
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'disable_join_to_group',
          'hasAnchor': true,
          'disabled': !_vm.$store.getters.hasChannelGroup
        },
      }}}),(_vm.activeChannel.config.disable_join_to_group)?_c('nested-content',[_c('multi-message-editor-with-media-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.messageSetter,
          'args': {
            'model': _vm.$store.state.channelsState.activeChannel.config,
            'key': 'comments_group_kick_message',
            'availableButtonsTypes': _vm.messageEditorButtons,
            'base-api-url': 'api.presscode.app/cabinet',
            'targetToUpload': {
              'target': 'target',
              'target_id': _vm.$store.getters.getBoardById(_vm.$route.params[ _vm.EntityTypes.BOT_ID ]).board
            },
            'buttonsHelpView': _vm.$t('select_editor_button_help_message'),
            'hasAnchor': true,
            'disabled': !_vm.$store.getters.hasChannelGroup
          }
        }}})],1):_vm._e(),_c('switch-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'disable_message_in_group',
          'translationParams': [ _vm.$store.getters.getBoardById(_vm.$route.params[ _vm.EntityTypes.BOT_ID ]).title ],
          'hasAnchor': true,
          'disabled': _vm.activeChannel.config.disable_join_to_group || !_vm.$store.getters.hasChannelGroup
        },
      }}}),(_vm.activeChannel.config.disable_join_to_group)?_c('a-alert',{attrs:{"show-icon":"","message":_vm.$t('disable_message_in_group_depends_on_disable_join_to_group_text')}}):_vm._e(),(!_vm.activeChannel.config.disable_join_to_group)?[(_vm.activeChannel.config.disable_message_in_group)?_c('nested-content',[_c('multi-message-editor-with-media-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
            'func': _vm.messageSetter,
            'args': {
              'model': _vm.$store.state.channelsState.activeChannel.config,
              'key': 'comments_group_mute_message',
              'availableButtonsTypes': _vm.messageEditorButtons,
              'base-api-url': 'api.presscode.app/cabinet',
              'targetToUpload': {
                'target': 'target',
                'target_id': _vm.$store.getters.getBoardById(_vm.$route.params[ _vm.EntityTypes.BOT_ID ]).board
              },
              'buttonsHelpView': _vm.$t('select_editor_button_help_message'),
              'hasAnchor': true,
              'disabled': !_vm.$store.getters.hasChannelGroup
            }
          }}})],1):_vm._e()]:_vm._e(),_c('switch-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'comments_only_for_subscribers',
          'translationParams': [ _vm.$store.getters.getBoardById(_vm.$route.params[ _vm.EntityTypes.BOT_ID ]).title ],
          'hasAnchor': true,
          'disabled': !_vm.$store.getters.hasChannelGroup
        },
      }}}),(_vm.activeChannel.config.comments_only_for_subscribers)?_c('nested-content',[_c('multi-message-editor-with-media-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.messageSetter,
          'args': {
            'model': _vm.$store.state.channelsState.activeChannel.config,
            'key': 'comments_warn_message',
            'availableButtonsTypes': _vm.messageEditorButtons,
            'base-api-url': 'api.presscode.app/cabinet',
            'targetToUpload': {
              'target': 'target',
              'target_id': _vm.$store.getters.getBoardById(_vm.$route.params[ _vm.EntityTypes.BOT_ID ]).board
            },
            'buttonsHelpView': _vm.$t('select_editor_button_help_message'),
            'hasAnchor': true,
            'disabled': !_vm.$store.getters.hasChannelGroup
          }
        }}}),_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.channelConfigSetup,
          'args': {
            'key': 'allow_comments_from_channels',
            'hasAnchor': true,
            'disabled': !_vm.$store.getters.hasChannelGroup
          },
        }}})],1):_vm._e(),_c('time-unit-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'spam_comment_timeout',
          'hasAnchor': true,
          'min': 0,
          'settingWrapper': {
            customiseValue: _vm.StepUnit.Minute,
            hasFieldButton: true,
            disabledValue: 0
          },
          'disabled': !_vm.$store.getters.hasChannelGroup
        }
      }}}),(_vm.activeChannel.config.spam_comment_timeout > 0)?_c('nested-content',[_c('multi-message-editor-with-media-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.channelConfigSetup,
          'args': {
            'key': 'comments_timeout_message',
            'availableButtonsTypes': _vm.messageEditorButtons,
            'base-api-url': 'api.presscode.app/cabinet',
            'targetToUpload': {
              'target': 'target',
              'target_id': _vm.$store.getters.getBoardById(_vm.$route.params[ _vm.EntityTypes.BOT_ID ]).board
            },
            'buttonsHelpView': _vm.$t('select_editor_button_help_message'),
            'hasAnchor': true,
            'disabled': !_vm.$store.getters.hasChannelGroup
          }
        }}})],1):_vm._e(),_c('time-unit-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'post_comments_max_age',
          'hasAnchor': true,
          'min': 0,
          'settingWrapper': {
            customiseValue: _vm.StepUnit.Minute,
            hasFieldButton: true,
            disabledValue: 0
          },
          'disabled': !_vm.$store.getters.hasChannelGroup
        }
      }}}),(_vm.activeChannel.config.post_comments_max_age > 0)?_c('nested-content',[_c('multi-message-editor-with-media-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.channelConfigSetup,
          'args': {
            'key': 'comments_expire_message',
            'availableButtonsTypes': _vm.messageEditorButtons,
            'base-api-url': 'api.presscode.app/cabinet',
            'targetToUpload':  {
              'target': 'target',
              'target_id': _vm.$store.getters.getBoardById(_vm.$route.params[ _vm.EntityTypes.BOT_ID ]).board
            },
            'buttonsHelpView': _vm.$t('select_editor_button_help_message'),
            'hasAnchor': true,
          }
        }}})],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }